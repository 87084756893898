define(() => {
    var signupConsent = () => {

        const component = {};
        
        const _init = (element) => {
            component.element = element;
            component.elements();
            component.bindEvents();
            document.getElementById("signup-submit-button").disabled = true;
        }

        const _elements = () => {
            component.singupElements = {
                newsletterCheckbox: component.element.querySelector(".signupConsent_newsletter_checkbox"),
                privacyPolicyCheckbox: component.element.querySelector(".signupConsent_privacy_policy_checkbox"),
                signupSubmitButton: document.querySelector(".widgetWaitList_signUpButton")
            }
        }

        const _bindEvents = () => {
            component.singupElements.newsletterCheckbox.addEventListener("change", component.checked);
            component.singupElements.privacyPolicyCheckbox.addEventListener("change", component.checked);
        }

        const _checked = (event) => {

            if((component.singupElements.newsletterCheckbox.checked) && (component.singupElements.privacyPolicyCheckbox.checked)) {
                component.singupElements.signupSubmitButton.classList.remove("disabled");
                document.getElementById("signup-submit-button").disabled = false;
            } else {
                component.singupElements.signupSubmitButton.classList.add("disabled");
                document.getElementById("signup-submit-button").disabled = true;
            }
        }

        component.init = _init;
        component.elements = _elements;
        component.bindEvents = _bindEvents;
        component.checked = _checked;
        
        return component;
    };

    return signupConsent;
});